import React, { useEffect, useState } from "react";
import { Table } from "rsuite";
const { Column, HeaderCell, Cell } = Table;

function RoomsRateplans({
  promo,
  rooms,
  ratePlans: rateplans,
  isEdit,
  handleFieldChange,
  setIsNextBtnDisabled,
}) {
  const fieldPath = "allowedRateRoomCodes";
  let selectedList = promo?.allowedRateRoomCodes || [];
  let selectedRP = [];
  let selectedRooms = [];
  let selectedRoomsAndRPs = [];
  let mapping = { allRoomRateplans: [], roomToRateplan: {}, rateToRoom: {} };
  rooms.forEach((room) => {
    mapping["allRoomRateplans"].push(room.code);
    mapping["roomToRateplan"][room.code] = [];
    rateplans.forEach((rp) => {
      if (!mapping["allRoomRateplans"].includes(rp.code))
        mapping["allRoomRateplans"].push(rp.code);
      mapping["roomToRateplan"][room.code].push(rp.code);
      if (!mapping["rateToRoom"][rp.code]) mapping["rateToRoom"][rp.code] = [];
      mapping["rateToRoom"][rp.code].push(room.code);
    });
  });

  if (!isEdit && selectedList.length === 0) {
    selectedList = [];
    selectedRP = [];
    selectedRooms = [];
    selectedRoomsAndRPs = [];
  } else {
    selectedRP = [];
    selectedRooms = [];
    selectedRoomsAndRPs = [];
    const selectedMapping = getSelectedMapping(selectedList);
    rooms.forEach((room) => {
      rateplans.forEach((rp) => {
        if (
          !selectedRP.includes(rp.code) &&
          selectedMapping["rateToRoom"][rp.code]?.length ===
            mapping["rateToRoom"][rp.code]?.length
        ) {
          selectedRP?.push(rp.code);
          selectedRoomsAndRPs?.push(rp.code);
        }
        if (
          !selectedRooms.includes(room.code) &&
          selectedMapping["roomToRateplan"][room.code]?.length ===
            mapping["roomToRateplan"][room.code]?.length
        ) {
          selectedRooms?.push(room.code);
          selectedRoomsAndRPs?.push(room.code);
        }
      });
    });
  }
  const [selectedCombo, setSelectedCombo] = useState([...selectedList]);
  const [slectedRateplan, setSelectedRateplan] = useState([...selectedRP]);
  const [slectedRooms, setSelectedRooms] = useState([...selectedRooms]);
  const getStatus = (roomCode, rateplanCode) => {
    return !!selectedCombo.find(
      (combo) => combo.mRight === roomCode && combo.mLeft === rateplanCode
    );
  };

  useEffect(() => {
    handleFieldChange(fieldPath, [...selectedCombo]);
    if (!selectedCombo || selectedCombo.length === 0) {
      setIsNextBtnDisabled(true);
    } else {
      setIsNextBtnDisabled(!selectedCombo);
    }
  }, [selectedCombo, handleFieldChange, fieldPath, setIsNextBtnDisabled]);

  const tableData = rooms.map((room) => {
    const row = {
      room: room.displayName,
      roomId: room.id,
      roomCode: room.code,
    };
    for (const rateplan of rateplans) {
      row[rateplan.code] = {
        roomId: room.id,
        roomCode: room.code,
        rateCode: rateplan.code,
        rateplan: rateplan.name,
        rateplanId: rateplan.id,
        checked: getStatus(room.code, rateplan.code),
      };
    }
    return row;
  });

  const [data, setData] = useState(tableData);

  function getSelectedMapping(selectedCombo) {
    return selectedCombo.reduce(
      (acc, c) => {
        if (!acc["roomToRateplan"][c.mRight])
          acc["roomToRateplan"][c.mRight] = [];
        if (!acc["roomToRateplan"][c.mRight].includes(c.mLeft))
          acc["roomToRateplan"][c.mRight].push(c.mLeft);
        if (!acc["rateToRoom"][c.mLeft]) acc["rateToRoom"][c.mLeft] = [];
        if (!acc["rateToRoom"][c.mLeft].includes(c.mRight))
          acc["rateToRoom"][c.mLeft].push(c.mRight);
        return acc;
      },
      { roomToRateplan: {}, rateToRoom: {} }
    );
  }

  const toggleCell = (cellData) => {
    if (!cellData.checked) {
      const finalSelectedCombo = [
        ...selectedCombo,
        { mRight: cellData.roomCode, mLeft: cellData.rateCode },
      ];
      setSelectedCombo(finalSelectedCombo);
      cellData.checked = true;
    } else {
      let temp = [...selectedCombo];
      const index = temp.findIndex(
        (combo) =>
          combo.mRight === cellData.roomCode &&
          combo.mLeft === cellData.rateCode
      );
      temp.splice(index, 1);
      cellData.checked = false;
      setSelectedCombo(temp);
      setSelectedRateplan(
        slectedRateplan.filter((code) => code !== cellData.rateCode)
      );
      setSelectedRooms(
        slectedRooms.filter((code) => code !== cellData.roomCode)
      );
    }
  };

  const selectAllRoomsForRateplan = (rateCode) => {
    const tableData = [...data];
    const combos = rooms.map((room) => {
      return { mRight: room.code, mLeft: rateCode };
    });
    const selectCombos = selectedCombo.filter(
      (combo) => combo.mLeft !== rateCode
    );
    if (slectedRateplan.includes(rateCode)) {
      setSelectedCombo(selectCombos);
      setSelectedRateplan(slectedRateplan.filter((code) => code !== rateCode));
      tableData.forEach((row) => {
        row[rateCode].checked = false;
      });
    } else {
      setSelectedCombo([...selectCombos, ...combos]);
      setSelectedRateplan([...slectedRateplan, rateCode]);
      tableData.forEach((row) => {
        row[rateCode].checked = true;
      });
    }
  };

  const selectAllRPForRoom = (roomCode) => {
    const tableData = [...data];
    const combos = rateplans.map((rateplan) => {
      return { mRight: roomCode, mLeft: rateplan.code };
    });
    const selectCombos = selectedCombo.filter(
      (combo) => combo.mRight !== roomCode
    );
    if (slectedRooms.includes(roomCode)) {
      setSelectedCombo(selectCombos);
      setSelectedRooms(slectedRooms.filter((code) => code !== roomCode));
      const row = tableData.find((row) => row.roomCode === roomCode);
      rateplans.forEach((rateplan) => {
        row[rateplan.code].checked = false;
      });
    } else {
      setSelectedCombo([...selectCombos, ...combos]);
      setSelectedRooms([...slectedRooms, roomCode]);
      const row = tableData.find((row) => row.roomCode === roomCode);
      rateplans.forEach((rateplan) => {
        row[rateplan.code].checked = true;
      });
    }
  };

  const selectAllRPAndRooms = () => {
    if (selectedRoomsAndRPs?.length !== mapping["allRoomRateplans"]?.length) {
      const selectedList = [];
      rooms.forEach((room) => {
        rateplans.forEach((rp) => {
          selectedList.push({ mLeft: rp.code, mRight: room.code });
        });
      });
      tableData.forEach((data) => {
        Object.keys(data).forEach((rateCode) => {
          if (data[rateCode] && typeof data[rateCode] === "object")
            data[rateCode].checked = true;
        });
      });
      setSelectedCombo(selectedList);
    } else {
      tableData.forEach((data) => {
        Object.keys(data).forEach((rateCode) => {
          if (data[rateCode] && typeof data[rateCode] === "object") {
            data[rateCode].checked = false;
          }
        });
      });
      setSelectedCombo([]);
    }
    setData(tableData);
  };

  return (
    <div className={"rooms-rateplans"}>
      <Table height={500} data={data}>
        <Column flexGrow={1} minWidth={160} align="center" fixed>
          <HeaderCell minWidth={160}>
            Rooms/Rateplans
            <span
              className="cursor-pointer check-span mL-5"
              onClick={selectAllRPAndRooms}
            >
              <i
                className={
                  selectedRoomsAndRPs?.length ===
                  mapping["allRoomRateplans"]?.length
                    ? "fa fa-check-square-o fa-lg"
                    : "fa fa-square-o fa-lg"
                }
              ></i>
            </span>
          </HeaderCell>
          <Cell>
            {(rowData) => (
              <div
                onClick={() => selectAllRPForRoom(rowData.roomCode)}
                title={rowData.room}
                className={"embolden ellipsis"}
              >
                {rowData.roomCode}

                <span
                  className="cursor-pointer check-span mL-5"
                  onClick={() => {
                    selectAllRPForRoom(rowData.roomCode);
                  }}
                >
                  <i
                    className={
                      selectedRooms.includes(rowData.roomCode)
                        ? "fa fa-check-square-o fa-lg"
                        : "fa fa-square-o fa-lg"
                    }
                  ></i>
                </span>
              </div>
            )}
          </Cell>
        </Column>
        {rateplans.map((rateplan) => {
          const minWidth =
            rateplan.code.length > 5 ? rateplan.code.length * 12 + 35 : 100;
          return (
            <Column
              flexGrow={1}
              minWidth={minWidth}
              key={rateplan.id}
              align="center"
            >
              <HeaderCell>
                <div
                  title={rateplan.name}
                  className={"embolden ellipsis mT-3"}
                  onClick={() => selectAllRoomsForRateplan(rateplan.code)}
                >
                  {rateplan.code}
                </div>
                <span
                  className="cursor-pointer check-span mL-5"
                  onClick={() => {
                    selectAllRoomsForRateplan(rateplan.code);
                  }}
                >
                  <i
                    className={
                      selectedRP.includes(rateplan.code)
                        ? "fa fa-check-square-o fa-lg"
                        : "fa fa-square-o fa-lg"
                    }
                  ></i>
                </span>
              </HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <span
                      className="cursor-pointer check-span"
                      onClick={() => {
                        toggleCell(rowData[rateplan.code]);
                      }}
                    >
                      <i
                        className={
                          rowData[rateplan.code].checked
                            ? "fa fa-check-square-o fa-lg"
                            : "fa fa-square-o fa-lg"
                        }
                      ></i>
                    </span>
                    /*<Button
                                            color={rowData[rateplan.id].checked ? "green" : "red"}
                                            onClick={() => {
                                                toggleCell(rowData[rateplan.id]);
                                            }}
                                        >
                                            {rowData[rateplan.id].checked ? "Selected" : "Not Selected"}
                                        </Button>*/
                  );
                }}
              </Cell>
            </Column>
          );
        })}
      </Table>
    </div>
  );
}

export default RoomsRateplans;
