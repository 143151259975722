import logo from "../../assets/img/HTLogo.svg";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { memo, useContext, useState } from "react";
import { PropertyStoreContext } from "../../contexts/PropertyStoreContext";
import PropertySelect from "../PropertySelect";

const Header = () => {
  const navigate = useNavigate();
  const { properties, setSelectedProperty, selectedProperty } =
    useContext(PropertyStoreContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigateToLogout = () => {
    navigate("/logout");
  };

  return (
    <header className={"header"}>
      <Link to="/">
        <img src={logo} className="logo" alt="Hoteltrader" />
      </Link>
      <PropertySelect
        formClassName="header-property-select"
        options={properties}
        onSelectChange={setSelectedProperty}
        selectedProperty={selectedProperty}
      />
      <Dropdown
        className="dropdown-main mR-20"
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <DropdownToggle>
          <i className="fa fa-power-off font-22 menu-icon" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <div className="dropdown-option" onClick={navigateToLogout}>
              <i className="fa fa-sign-out" />
              Sign Out
            </div>
          </DropdownItem>
        </DropdownMenu>
        <br />
      </Dropdown>
    </header>
  );
};

export default memo(Header);
