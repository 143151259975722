import React from "react";
import { Spinner } from "reactstrap";

const Loader = ({ loading }) => {
  return (
    loading && (
      <div className="loader-overlay">
        <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
      </div>
    )
  );
};

export default Loader;
