import { useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export function Callback() {
    const context = useAuth();

    const { idTokenData, logOut, idToken, loginInProgress, login } = context;

    const navigate = useNavigate();

    useEffect(() => {
        if (idToken) {
            navigate("/");
        }
        if (!loginInProgress && !idToken) {
            navigate("/auth");
        }
    }, [idToken, loginInProgress, navigate]);

    if (loginInProgress) {
        return (
            <div>
                <div>Logging in...</div>
            </div>
        );
    }

    if (!idToken) {
        return (
            <div>
                <div>
                    Not logged in. <button onClick={login}>Login</button>
                </div>
                <button
                    onClick={() => {
                        logOut();
                        login();
                    }}
                >
                    Reset
                </button>{" "}
            </div>
        );
    }

    return (
        <div>
            <button onClick={() => logOut()}>LogOut</button>{" "}
            <Link to={"/users2"}>User management</Link>
            <>
                <h4>Successfully logged in. Id Token is: </h4>
                <pre>{idToken}</pre>
                <h4>User Information from JWT</h4>
                <pre>{JSON.stringify(idTokenData, null, 2)}</pre>
            </>
        </div>
    );
}
