import React, { useState, useEffect } from "react";
import { FormGroup, Input } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const PropertySelect = ({
  options,
  onSelectChange,
  selectedProperty,
  formClassName,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    selectedProperty?.propertyId,
  );

  useEffect(() => {
    if (selectedValue) {
      const selectedObject = options.find(
        (option) => option.propertyId === +selectedValue,
      );
      onSelectChange(selectedObject);
    }
  }, [selectedValue, onSelectChange, options]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      {options.length > 0 ? (
        <FormGroup className={formClassName}>
          <Input
            type="select"
            name="select"
            id="propertySelect"
            style={{ border: "none" }}
            value={selectedValue}
            onChange={handleSelectChange}
          >
            {options?.map((option) => (
              <option key={option.propertyId} value={option.propertyId}>
                {option.propertyName}
              </option>
            ))}
          </Input>
        </FormGroup>
      ) : (
        <span className={`${formClassName} no-prop-span`}>No properties</span>
      )}
    </>
  );
};

export default PropertySelect;
