import { USER_ROLES } from "../constants";

const { SUPER_USER, USER } = USER_ROLES;

const rules = {
    [USER]: {
        static: ["teamMembers"],
        homePage: "/",
    },
    [SUPER_USER]: {
        static: ["dashboard", "teamMembers", "documentation", "certification"],
        homePage: "/",
    },
};

export default rules;
