export const DATE_FILTER_OPTIONS = [
  {
    label: "last 7 days",
    value: "LAST_7_DAYS",
  },
  {
    label: "last 30 days",
    value: "LAST_30_DAYS",
  },
  {
    label: "last 90 days",
    value: "LAST_90_DAYS",
  },
  {
    label: "last 365 days",
    value: "LAST_365_DAYS",
  },
];

export const calculateDates = (selectedOption) => {
  const endDate = new Date();
  let startDate = new Date();

  // Calculate start date based on selected option
  switch (selectedOption) {
    case "LAST_7_DAYS":
      startDate.setDate(endDate.getDate() - 6); // Subtract 6 days to get 7 days ago
      break;
    case "LAST_30_DAYS":
      startDate.setDate(endDate.getDate() - 29); // Subtract 29 days to get 30 days ago
      break;
    case "LAST_90_DAYS":
      startDate.setDate(endDate.getDate() - 89); // Subtract 89 days to get 90 days ago
      break;
    case "LAST_365_DAYS":
      startDate.setDate(endDate.getDate() - 364); // Subtract 364 days to get 365 days ago
      break;
    default:
      break;
  }

  // Format dates to 'yyyy-mm-dd'
  const formattedStartDate = startDate.toISOString().split("T")[0];
  const formattedEndDate = endDate.toISOString().split("T")[0];

  return { startDate: formattedStartDate, endDate: formattedEndDate };
};

export const formatDate = (date) => {
  if (!date) return null;
  return date.toISOString().split("T")[0];
};

export const calculatePreviousDateRange = (
  currentStartDate,
  currentEndDate
) => {
  const startDate = new Date(currentStartDate);
  const endDate = new Date(currentEndDate);
  const prevEndDate = new Date(startDate.getTime() - 1); // Previous end date is one day before the current start date
  const prevStartDate = new Date(prevEndDate.getTime() - (endDate - startDate)); // Previous start date is the same duration before the previous end date as the current date range

  // Format dates to 'yyyy-MM-dd'
  const formattedPrevStartDate = prevStartDate.toISOString().split("T")[0];
  const formattedPrevEndDate = prevEndDate.toISOString().split("T")[0];

  return { startDate: formattedPrevStartDate, endDate: formattedPrevEndDate };
};

export const getRoundToTwoDecimals = (value, noOfDecimals = 2) => {
  if (isNaN(value)) return value;
  return (
    Math.round(value * Math.pow(10, noOfDecimals)) / Math.pow(10, noOfDecimals)
  );
};

export const deepClone = (obj) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    return obj;
  }
};

export const getPowerBIPropertyFilter = (propertyCode) => ({
  $schema: "https://powerbi.com/product/schema#basic",
  target: {
    table: "dimProperty",
    column: "code",
  },
  operator: "In",
  values: [propertyCode],
  displaySettings: { isLockedInViewMode: true },
});
