import { FormGroup, Input, Label } from "reactstrap";
import { useEffect } from "react";

const PromoTitle = ({ setIsNextBtnDisabled, fieldValue, onFieldChange }) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    onFieldChange("rateRule.ruleVO.descriptionVO.name", newValue);
  };

  useEffect(() => {
    if (!fieldValue) {
      setIsNextBtnDisabled(true);
    } else {
      setIsNextBtnDisabled(false);
    }
  }, [fieldValue, setIsNextBtnDisabled]);

  return (
    <div className="create-promo-title">
      <FormGroup>
        <Label for="promoTitle" className="create-promo-label">
          Let’s give your promo a name
        </Label>
        <Input
          id="promoTitle"
          name="promoTitle"
          placeholder="Example: Last minute getaway deal"
          type="text"
          className="create-promo-input"
          value={fieldValue}
          onChange={handleChange}
        />
      </FormGroup>
    </div>
  );
};

export default PromoTitle;
