import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthContext";
import { toast } from "react-toastify";
import { getProperties } from "../api";
import Loader from "../components/Loader";

export const PropertyStoreContext = React.createContext({});

export const PropertyStoreProvider = React.memo(function PropertyStoreProvider({
  children,
}) {
  const { idToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const getAllProperties = useCallback(() => {
    setLoading(true);
    getProperties().then(
      (res) => {
        setLoading(false);
        const properties = res?.data?.data;
        setProperties(properties);
        setSelectedProperty(properties[0] ?? null);
      },
      () => {
        setLoading(false);
        toast.error("Unable to load property, please try again later");
      }
    );
  }, []);

  useEffect(() => {
    if (idToken) {
      getAllProperties();
    }
  }, [idToken, getAllProperties]);

  const contextValue = useMemo(
    () => ({
      selectedProperty,
      setSelectedProperty,
      properties,
    }),
    [properties, selectedProperty, setSelectedProperty]
  );

  return (
    <PropertyStoreContext.Provider value={contextValue}>
      {<Loader loading={loading} />}
      {children}
    </PropertyStoreContext.Provider>
  );
});
