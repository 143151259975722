import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { Callback } from "./pages/login/Callback";
import LoginPage from "./pages/login/Login";
import { AuthProvider } from "./contexts/AuthContext";
import RootLayout from "./pages/Root";
import Dashboard from "./pages/dashboard/Dashboard";
import RequireAuth from "./route-guards/RequireAuth";
import Logout from "./pages/login/Logout";
import Promotions from "./pages/promotions/Promotions";
import Unauthorized from "./pages/unauthorized/Unauthorized";

import 'react-toastify/dist/ReactToastify.css';
import "./App.css";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/auth" element={<LoginPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/auth/callback" element={<Callback />} />
          <Route path="/" element={<RequireAuth page="all" />}>
            <Route path="/" element={<RootLayout />}>
              <Route element={<RequireAuth page="dashboard" />}>
                <Route index element={<Dashboard />} />
              </Route>
              <Route element={<RequireAuth page="promotions" />}>
                <Route path="/promotions" element={<Promotions />} />
              </Route>
            </Route>
          </Route>
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </Router>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
