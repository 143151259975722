import { useAuth } from "../../contexts/AuthContext";
import { memo, useEffect } from "react";

const Logout = () => {
    const { logOut } = useAuth();

    useEffect(() => {
        console.log("logout");
        logOut();
    }, [logOut]);

    return <></>;
};

export default memo(Logout);
