import React, { useCallback, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import "./promotions.scss";
import CreatePromo from "../../components/create-promo/CreatePromo";
import { deepClone } from "../../constants/utils";
import {
  PROMO_OBJECT,
  DEFAULT_CALCULATION_TYPE,
  DEFAULT_CALCULATION_TYPE_FOR_PEGS_OFFLINE,
  PEGS_CRS_ID,
  OFFLINE_CRS_ID,
} from "./constants";

import giftIcon from "../../assets/img/gift.png";

const PromotionCreatePromo = ({
  ratePlans,
  rooms,
  modal,
  setModal,
  promo,
  setPromo,
  isEdit,
  setIsEdit,
  setIsGetPromos,
  setLoading,
  calculationType,
  setCalculationType,
  property,
}) => {
  const [modalTitle, setModalTitle] = useState("1. Promo Title");

  const toggle = () => {
    if (modal) {
      setPromo(null);
    } else {
      setPromo(deepClone(PROMO_OBJECT));
      const { crsId } = property;
      if (PEGS_CRS_ID === crsId || OFFLINE_CRS_ID === crsId) {
        setCalculationType(DEFAULT_CALCULATION_TYPE_FOR_PEGS_OFFLINE);
      } else {
        setCalculationType(DEFAULT_CALCULATION_TYPE);
      }
    }
    setModal(!modal);
    setIsEdit(false);
  };

  const promoSaved = () => {
    setModal(false);
    setPromo(null);
    setIsGetPromos({});
  };

  const stepperChangeCallback = useCallback((stepCount) => {
    switch (stepCount) {
      case 0:
        setModalTitle("Welcome to promo creation");
        return;
      case 1:
        setModalTitle("1. Promo Title");
        return;
      case 2:
        setModalTitle("2. Targeting conditions");
        return;
      case 3:
        setModalTitle("3. Offer type");
        return;
      case 4:
        setModalTitle("4. Rooms and rateplans");
        return;
      case 5:
        setModalTitle("Summary");
        return;
      default:
        setModalTitle("1. Promo Title");
        return;
    }
  }, []);

  return (
    <div className="promotions-create-promo">
      <p className="promotions-create-promo-header">
        <img src={giftIcon} alt="gift icon" />
        <span>Create</span> your own promo
      </p>
      <p className="promotions-create-promo-desc">
        You can create your own promos from scratch or choose from our growing
        list of pre-made templates which should cover most of your basic needs.
      </p>
      <Button
        type="submit"
        className="w-100 p-10"
        size="lg"
        color="primary"
        onClick={toggle}
      >
        Create Promo
      </Button>
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="xl"
          centered
          returnFocusAfterClose={false}
          className="create-promo-modal"
        >
          <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
          <ModalBody>
            <CreatePromo
              promo={promo}
              setPromo={setPromo}
              stepperChangeCallback={stepperChangeCallback}
              ratePlans={ratePlans}
              rooms={rooms}
              isEdit={isEdit}
              hideModal={promoSaved}
              setLoading={setLoading}
              calculationType={calculationType}
              setCalculationType={setCalculationType}
            />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default PromotionCreatePromo;
