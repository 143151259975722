import { Row, Col } from "reactstrap";
import { useCallback, useEffect, useState } from "react";
import { getPromotionsSummary } from "../../api";
import {
  calculateDates,
  calculatePreviousDateRange,
  getRoundToTwoDecimals,
} from "../../constants/utils";
import { calculatePercentageChanges } from "./constants";

const FILTER_MAPPINGS = {
  LAST_7_DAYS: "previous 7 days",
  LAST_30_DAYS: "previous 30 days",
  LAST_90_DAYS: "previous 90 days",
  LAST_365_DAYS: "previous 365 days",
};
const PromotionsKPIs = ({ range, selectedProperty, setLoading }) => {
  const [summaryData, setSummaryData] = useState();
  const [percentsData, setPercentsData] = useState({});

  const kpis = [
    {
      header: "Revenue generated by promos",
      fieldKey: "totalRevenue",
      suffix: "USD",
      percentField: "revenueChange",
    },
    {
      header: "Room nights generated by promos",
      fieldKey: "totalNights",
      percentField: "nightsChange",
    },
    {
      header: "Reservation generated by promos",
      fieldKey: "totalReservations",
      percentField: "reservationsChange",
    },
    {
      header: "Clients booking promos",
      fieldKey: "totalUniqueClients",
      percentField: "uniqueClientsChange",
    },
  ];

  useEffect(() => {
    if (selectedProperty?.propertyId) {
      setLoading(true);
      const { startDate, endDate } = calculateDates(range);
      const { startDate: prevStartDate, endDate: prevEndDate } =
        calculatePreviousDateRange(startDate, endDate);
      Promise.all([
        getPromotionsSummary({
          propertyId: selectedProperty?.propertyId,
          startDate,
          endDate,
        }),
        getPromotionsSummary({
          propertyId: selectedProperty?.propertyId,
          startDate: prevStartDate,
          endDate: prevEndDate,
        }),
      ]).then(([res1, res2]) => {
        setLoading(false);
        setSummaryData(res1.data);
        setPercentsData(calculatePercentageChanges(res1.data, res2.data));
      });
    }
  }, [selectedProperty, range, setLoading]);

  const getPercent = useCallback(
    (kpi) => {
      return percentsData[kpi.percentField];
    },
    [percentsData],
  );

  return (
    <Row>
      {summaryData &&
        kpis.map((kpi, idx) => (
          <Col md={3} key={`promotion-kpi-${idx}`}>
            <div className="promotion-kpi">
              <p className="promotion-kpi-header">{kpi.header}</p>
              <p className="promotion-kpi-value">
                <span className="promotion-kpi-value-span">
                  {getRoundToTwoDecimals(summaryData[kpi.fieldKey])}{" "}
                  {kpi.suffix}
                </span>
                <span
                  className={`promotion-percent ${getPercent(kpi) < 0 ? "promotion-percent-negative" : ""}`}
                >
                  {getPercent(kpi) < 0 ? (
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                  )}
                  {getPercent(kpi)}%
                </span>
              </p>
              <p className="promotion-kpi-previous">
                vs. {FILTER_MAPPINGS[range]}
              </p>
            </div>
          </Col>
        ))}
    </Row>
  );
};

export default PromotionsKPIs;
