import { Col, Row } from "reactstrap";

const CannedPromos = ({ stepChange, fieldChange, setTc }) => {
  const canns = [
    {
      title: "Create a new promo",
      description:
        "Use this option if you want \n" +
        "to customize all promo features from scratch",
      type: "NEW",
    },
    {
      title: "Early booking promo",
      description:
        "Offer customers additional\n" +
        "deals & discounts if they\n" +
        "make their bookings early.",
      type: "EARLY_BOOKING",
    },
    {
      title: "Last minute promo",
      description:
        "Promotional offers and\n" +
        "discounts provided to last\n" +
        "minute bookings.",
      type: "LAST_MINUTE",
    },
    {
      title: "Turbo early booking discount",
      description:
        "Offer customers additional\n" +
        "deals & discounts if they\n" +
        "make their bookings early.",
      type: "TURBO_EARLY",
    },
    {
      title: "Long stay promo",
      description:
        "Offer custom deals and\n" +
        "discounts to customers\n" +
        "staying for a longer duration.",
      type: "LONG_STAY",
    },
  ];

  const resetPromo = () => {
    fieldChange("rateRule.ruleVO.stayVO.maxBookingLeadTime", 1);
    fieldChange("rateRule.ruleVO.stayVO.minBookingLeadTime", 1);
    fieldChange("rateRule.ruleVO.stayVO.minLengthOfStay", null);
  };

  const updatePromo = (type) => {
    resetPromo();
    switch (type) {
      case "EARLY_BOOKING":
        fieldChange("rateRule.ruleVO.bookingVO.minBookingLeadTime", 60);
        stepChange("next");
        setTc((prevState) => ({ ...prevState, showLT: true }));
        break;
      case "LAST_MINUTE":
        fieldChange("rateRule.ruleVO.bookingVO.maxBookingLeadTime", 21);
        setTc((prevState) => ({ ...prevState, showLT: true }));
        stepChange("next");
        break;
      case "TURBO_EARLY":
        fieldChange("rateRule.ruleVO.bookingVO.minBookingLeadTime", 120);
        setTc((prevState) => ({ ...prevState, showLT: true }));
        stepChange("next");
        break;
      case "LONG_STAY":
        fieldChange("rateRule.ruleVO.stayVO.minLengthOfStay", 3);
        setTc((prevState) => ({ ...prevState, showLOS: true }));
        stepChange("next");
        break;
      default:
        stepChange("next");
    }
  };

  return (
    <Row className="canned-promo-container">
      <Col md={12}>
        <p className="canned-promo-header">
          create your own promo from scratch or choose from a pre-made template
          with default parameters filled in.
        </p>
      </Col>
      {canns.map((promo) => (
        <Col md={4} className="mb-5" key={promo.type}>
          <div className="canned-promo-wrapper">
            <div className="canned-header">{promo.title}</div>
            <div className="canned-desc">{promo.description}</div>
            <div
              className="canned-footer"
              onClick={() => updatePromo(promo.type)}
            >
              continue
              <i className="fa fa-long-arrow-right" />
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default CannedPromos;
