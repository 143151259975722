import "./footer.scss";
import { memo } from "react";

const Footer = () => {
  return (
    <footer className={"footer"}>
      <p className="copy-right-text"></p>
    </footer>
  );
};

export default memo(Footer);
