export const USER_ROLES = {
  USER: "USER",
  SUPER_USER: "SUPER_USER",
};

export const TOKEN_TYPE = {
  // Aad: 0,
  Embed: 1,
};

export const FILTERS_OPERATIONS = {
  // RemoveAll: 0,
  ReplaceAll: 1,
  // Add: 2,
  // Replace: 3
};

export const POWER_BI_SETTINGS = {
  navContentPaneEnabled: false,
};
