import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = ({
  text,
  to,
  iconClass,
  isDisabled,
  className = "",
  target = "",
}) => {
  const getLinkClassName = (isActive) => {
    const className = isDisabled
      ? "sidebar-link nav-link disabled"
      : "sidebar-link";
    if (isActive) {
      return className + " active";
    }

    return className;
  };
  return (
    <li className={`sidebar-list-item ${className}`}>
      <NavLink
        to={to}
        target={target}
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <i className={iconClass}></i>
        <div className="sidebar-link-text">{text}</div>
      </NavLink>
    </li>
  );
};

export default NavItem;
