import React from "react";
import logo from "../../../assets/img/HTLogo.svg";
import "./htLoader.scss";

const HTLoader = () => {
  return (
    <div className="ht-loading position-absolute top-50 start-50 translate-middle">
      <img alt="Hotel Trader loading" src={logo} width="61" />
      <div className="shine"></div>
    </div>
  );
};

export default HTLoader;
