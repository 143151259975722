import React from "react";

import Report from "../../components/power-bi/Report";

const Dashboard = () => {
  return (
    <div id="dashboard" data-heap-id="dashboard">
      <Report />
    </div>
  );
};

export default Dashboard;
