import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import React, { useState } from "react";
import TargetingConditions from "./TargetingConditions";
import OfferType from "./OfferType";
import RoomsRateplans from "./RoomsRateplans";
import PromoTitle from "./PromoTitle";

const Summary = ({
  setIsNextBtnDisabled,
  promo,
  onFieldChange,
  ratePlans,
  rooms,
  tc,
  setTc,
  isEdit,
  calculationType,
  setCalculationType,
}) => {
  const [editTitle, setEditTitle] = useState(false);
  const [open, setOpen] = useState(isEdit ? null : "1");
  const toggle = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  return (
    <div className="summary-body">
      <div className="overlap-body">
        <div>
          <i className="fa fa-files-o" aria-hidden="true"></i>
        </div>
        <div className="overlap-body-details">
          <p className="overlap-body-header">Got more than one promo? Great!</p>
          <p className="overlap-body-desc">
            {`But remember, at booking, it's a "one promo at a time" party. We
            will only apply one promo to each booking.`}
          </p>
        </div>
      </div>
      <div>
        {!editTitle ? (
          <p className="summary-promo-title">
            <i className="fa fa-pencil" onClick={() => setEditTitle(true)}></i>
            Title: {promo?.rateRule?.ruleVO?.descriptionVO?.name}
          </p>
        ) : (
          <PromoTitle
            setIsNextBtnDisabled={setIsNextBtnDisabled}
            fieldValue={promo?.rateRule?.ruleVO?.descriptionVO?.name || ""}
            onFieldChange={onFieldChange}
          />
        )}
      </div>
      <div className="accordion-wrapper">
        <Accordion open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="1">
              1. Targeting Condition
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <TargetingConditions
                tc={tc}
                setTc={setTc}
                promo={promo}
                handleFieldChange={onFieldChange}
                setIsNextBtnDisabled={setIsNextBtnDisabled}
              />
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="2">2. Offer type</AccordionHeader>
            <AccordionBody accordionId="2">
              <OfferType
                promo={promo}
                onFieldChange={onFieldChange}
                setIsNextBtnDisabled={setIsNextBtnDisabled}
                calculationType={calculationType}
                setCalculationType={setCalculationType}
              />
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="3">
              3. Rooms and rateplans
            </AccordionHeader>
            <AccordionBody accordionId="3">
              <RoomsRateplans
                promo={promo}
                handleFieldChange={onFieldChange}
                ratePlans={ratePlans}
                rooms={rooms}
                isEdit={isEdit}
                setIsNextBtnDisabled={setIsNextBtnDisabled}
              />
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default Summary;
