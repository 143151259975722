import "./sidenav.scss";
import { memo } from "react";
import NavItem from "./NavItem";

const SideNav = ({ isOpen, toggleSidebar }) => {
  return (
    <aside className={isOpen ? "sidebar open" : "sidebar"}>
      <div className="top-sidebar">
        <ul className="sidebar-list">
          <li className="sidebar-list-item mb-4">
            <button
              className={
                isOpen
                  ? "sidebar-link menu-icon-btn text-start active"
                  : "sidebar-link menu-icon-btn text-start"
              }
              onClick={toggleSidebar}
            >
              <i className={"fa fa-chevron-circle-right"} />
              <div className="sidebar-link-text">HT Supplier Portal</div>
            </button>
          </li>
          <NavItem to="/" text="Dashboard" iconClass="fa fa-home" />
          <NavItem to="/promotions" text="Promotions" iconClass="fa fa-gift" />
        </ul>
      </div>

      <div className="bottom-sidebar">
        <li className="sidebar-list-item mb-4">
          <div className="sidebar-link sidebar-link-text">Coming soon</div>
        </li>
        <NavItem
          to="/analytics"
          text="Analytics"
          iconClass="fa fa-line-chart"
          isDisabled
        />
        <NavItem
          to="/ari-search"
          text="ARI Search"
          iconClass="fa fa-wpexplorer"
          isDisabled
        />
        <NavItem
          to="/reservations"
          text="Res Report"
          iconClass="fa fa-file-text-o"
          isDisabled
        />
        <NavItem
          to="/qps"
          text="QPS"
          iconClass="fa fa-search-plus"
          isDisabled
        />
        <NavItem
          to="https://hoteltrader.knowledgeowl.com/help/supplier-portal"
          text="Help Center"
          iconClass="fa fa-lightbulb-o icon-lightbulb"
          className="help-center"
          target="_blank"
        />
      </div>
    </aside>
  );
};

export default memo(SideNav);
